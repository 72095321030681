@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Primary gradient colors */
.bg-gradient-to-r.from-primary-500 { background: linear-gradient(to right, #4a90e2, #0076d6, #5e4b8b); }
.bg-gradient-to-r.via-primary-600 { background: linear-gradient(to right, #0076d6, #5e4b8b, #7d3f7d); }
.bg-gradient-to-r.to-primary-700 { background: linear-gradient(to right, #5e4b8b, #7d3f7d, #a74a9b); }

/* Text colors */
.text-primary-500 { color: #4a90e2; }
.text-primary-600 { color: #0076d6; }
.text-primary-700 { color: #5e4b8b; }
.text-accent-200 { color: #f7f7f7; } /* Slightly lighter accent color */
.text-accent-300 { color: #f1c40f; } /* Yellow accent color */
.text-secondary-800 { color: #2c3e50; } /* Darker secondary color */

/* Background colors */
.bg-secondary-800 { background-color: #2c3e50; }
